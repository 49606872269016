import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  private currentLanguage: string = 'en'; // Langue par défaut

  images: any = {
    'fr': {
      'about': './assets/img/fr/1.jpg',
      'carousel2': './assets/img/fr/modèle2.jpg',
      'carousel3': './assets/img/fr/modèle15.jpg'
    },
    'en': {
      'about': './assets/img/en/1.jpg',
      'carousel2': './assets/img/en/modèle2.jpg',
      'carousel3': './assets/img/en/modèle15.jpg'
    },
    'ar': {
      'about': './assets/img/ar/1.jpg',
      'carousel2': './assets/img/ar/modèle2.jpg',
      'carousel3': './assets/img/ar/modèle15.jpg'
    },
    'zh': {
      'about': './assets/img/zh/1.jpg',
      'carousel2': './assets/img/zh/modèle2.jpg',
      'carousel3': './assets/img/zh/modèle15.jpg'
    }
  };

  constructor(private translate: TranslateService) {
    this.currentLanguage = this.translate.currentLang || 'fr'; // Définir la langue initiale
  }

  setLanguage(language: string) {
    this.currentLanguage = language;
    //this.translate.use(language);
  }

  getCurrentImages() {
    return this.images[this.currentLanguage];
  }
}
